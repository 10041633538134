.NavBar {
    width: 100%;
    display: inline-block;
}

.Center {
    align-items: center;
}

.Logo {
    float: left;
    padding: 0px 0px 0px 20px;
    font-size: 24px;
}

.StickyLogo {
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px;
    font-size: 24px;
    z-index: 1000;
}

.NavLink {
    float: right;
    padding: 20px;
}

.Title {
    font-weight: bold;
    padding: 30px;
}

.Version {
    margin-top: 2px;
    margin-left: 10px;
    font-size: 10px;
}