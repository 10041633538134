.file-upload {
    border: 2px dashed #ccc;
    padding: 20px;
    border-radius: 5px;
    cursor: pointer;
}

.file-upload.active {
    background-color: #f0f0f0;
}

.FileUploadPara {
    color: black!important;
}