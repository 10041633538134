.Next {
    background-color: red;
    height: 100%;
    width: 10px;
    display: inline-flex;
}

.Previous {
    background-color: green;
    height: 100%;
    width: 10px;
    display: inline-flex;
}