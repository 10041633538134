.DeleteBtn {
    margin-top: 20px;
    margin-bottom: 50px;
}

.CreateElementBtn {
    margin: 25px;
}

.CharactersList {
    margin: auto;
    width: 600px;
    padding: 25px;
}

.CharacterChip {
    margin: 5px;
    display: inline-block;
}

.CoverImg {
    width: 200px !important;
    height: auto !important;
    cursor: pointer;
    border-radius: 5px;
}

@media (max-width: 768px) {
    .StickyLogo {
      display: none;
    }
  }