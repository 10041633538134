/* Dark Mode Styles */

@keyframes fade-in-color {
    100% {
        color: inherit;
    }

    0% {
        color: goldenrod;
        /* Light color for dark mode */
    }
}

.text-gray-500 {
    animation: fade-in-color 2s ease-in forwards;
}

.bg-gray-900 {
    background-color: #111827;
    /* Dark background color */
}

.bg-gray-700 {
    background-color: inherit;
    /* Inherit background color */
}

.text-white {
    color: #ffffff;
    /* Light text color for dark mode */
}

.wrapper {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.overflow-hidden {
    overflow: hidden;
}

.relative {
    position: relative;
}

.px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.min-h-[56px] {
    min-height: 56px;
}

.absolute {
    position: absolute;
}

.top-2 {
    top: 0.5rem;
}

.right-3 {
    right: 0.75rem;
}

.cursor-pointer {
    cursor: pointer;
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

/* Light Mode Styles */

@media (prefers-color-scheme: light) {
    @keyframes fade-in-color {
        100% {
            color: inherit;
        }

        0% {
            color: salmon;
            /* Red color for light mode highlights */
        }
    }

    .text-gray-500 {
        animation: fade-in-color 2s ease-in forwards;
    }

    .bg-gray-900 {
        background-color: #f0f0f0;
        /* Light background color */
    }



    .text-white {
        color: #000000;
        /* Dark text color for light mode */
    }
}