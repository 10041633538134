.App {
  text-align: center;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Paragraphs {
  padding: 10px;
}

.Footnotes {
  padding: 20px;
  width: 800px;
  margin: auto;
}

.FootnoteTable {
  width: 950px;
  text-align: center;
  border-collapse: separate;
  border-spacing: 15px 5px;
}

.FootnoteTextarea {
  width: 100%;
  height: 80px;
  resize: none;
}

.SourceTextArea {
  width: 100%;
  height: 300px;
  font-size: 16px;
  resize: vertical;
}

.Source {
  font-size: 20px;
  float: left;
  width: 100%;
  text-align: left;
  text-indent: 25px;
}

.EditorTable {
  width: 100%;
  text-align: center;
  border-collapse: separate;
  border-spacing: 10px 5px;
}

blockquote {
  font-size: 15px;
}


.EditorTable td {
  padding: 5px 20px;
  box-shadow: 0px 0px 20px #e7e7e700;
  background: var(--white-background-color);
  color: var(--text-color);
  position: relative
}

.EditorTable th {
  width: 50%;
}

.Target {
  width: 100%;
  height: 100%;
  resize: none;
  position: absolute;
  top: 0;
  left: 0;

}

.TargetPara {
  min-width: 600px;
  padding: 10px;
  color: var(--text-color);
}

.AIPromptTextarea {
  width: 100%;
  height: 120px;
}

.TargetParagraphs {
  font-family: 'Times New Roman', Times, serif;
  font-size: 20px;
  margin: auto;
  text-align: left;
  width: 600px;
  text-indent: 25px;
  margin-bottom: 30px;
}

.PresetPrompt {
  margin: 5px !important;
}

.Accordions {
  width: 95%;
  margin: auto;
}

.FormatBtn {
  padding: 30px;
}

.PromptButtons {
  padding-top: 5px;
  padding-bottom: 10px;
}

.ProofFootnote {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
  margin: auto;
  text-align: left;
  text-indent: 25px;
}

.LoginBox {
  width: 400px;
  margin: auto;
  padding: 10px;
}

.SaveBtn {
  margin-right: 30px;
  float: right;
}

.BottomBtns {
  display: block;
  width: auto;
}

.BtnBox {
  display: inline-flex;
  padding: 30px 20px;
}

.BtnBoxToc {
  display: inline-flex;
  padding: 30px 20px;
  margin-right: 40px;
}

.FabBtnBoxTocExtended {
  position: fixed!important;
  top: 15px; /* Adjust as needed */
  right: 15px; /* Adjust as needed */
  z-index: 1001; /* Ensure it's on top of other content */
}

.FabBtnBoxToc {
  position: fixed;
  top: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  z-index: 1001; /* Ensure it's on top of other content */
}

.FabBtnBox {
  position: fixed;
  bottom: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  z-index: 1000; /* Ensure it's on top of other content */
}

.FabScrollBtn {
  position: fixed;
  right: 30px;
  top: 90px;
  z-index: 1000; /* Ensure it's on top of other content */
}



.DeleteBtnEditor {
  margin-left: 30px;
  float: left;
}

.TocLink {
  margin-top: 40px;
}

.custom-snackbar {
  left: 200px; /* Adjust this value to move it further right or left */
}