body .w-md-editor-text-pre>code,
body .w-md-editor-text-input {
  font-size: 18px !important;
  line-height: 22px !important;
}


.three-columns {
  display: flex;
  /* Uses Flexbox to layout children in a row */
  justify-content: space-between;
  /* Distributes space between children */
  align-items: center;
  /* Vertically centers items in their container */
  padding: 10px;
  /* Adds some padding around the content */
}

.column {
  flex: 1;
  /* Each column takes equal amount of space */
  text-align: center;
  /* Centers text in each column */
}

.column.left {
  text-align: center;
  /* Aligns text to the left in the left column */
}

.column.right {
  text-align: right;
  /* Aligns text to the right in the right column */
}

#logoStatic {
  margin: 5px;
}

.FabBtnBoxDiff {
  position: fixed !important;
  bottom: 20px !important;
  /* Adjust as needed */
  right: 20px !important;
  /* Adjust as needed */
  z-index: 1000;
  /* Ensure it's on top of other content */
}

.FabBtnDeletePdf {
  position: fixed !important;
  bottom: 20px !important;
  /* Adjust as needed */
  left: 20px !important;
  /* Adjust as needed */
  z-index: 1000;
  /* Ensure it's on top of other content */
}

.FabBtnStopAutoScan {
  position: fixed !important;
  bottom: 90px !important;
  /* Adjust as needed */
  right: 20px !important;
  /* Adjust as needed */
  z-index: 1000;
  /* Ensure it's on top of other content */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
  scrollbar-width: none;
  /* For Firefox */
}

.Linear {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999999 !important;
  /* Increased z-index */
  pointer-events: none;
  /* Allows clicking through the progress bar */
}

.FabComplete {
  position: absolute !important;
  top: 20px !important;
  /* Adjust as needed */
  right: 100px !important;
  /* Adjust as needed */
  z-index: 1000;
  /* Ensure it's on top of other content */
}

.TTSIcons {
  position: fixed !important;
  top: 100px !important;
  /* Adjust as needed */
  right: 20px !important;
  /* Adjust as needed */
  z-index: 1000;
  /* Ensure it's on top of other content */
}

.FabCompleteSmall {
  display: none;
  position: absolute !important;
  top: 20px !important;
  /* Adjust as needed */
  right: 100px !important;
  /* Adjust as needed */
  z-index: 1000;
  /* Ensure it's on top of other content */
}

.FabScan {
  opacity: 0.9;
  position: fixed !important;
  top: 20px !important;
  /* Adjust as needed */
  left: 100px !important;
  /* Adjust as needed */
  z-index: 1003;
  /* Ensure it's on top of other content */
}

.FabScanSmall {
  opacity: 0.9;
  display: none;
  position: fixed !important;
  top: 20px !important;
  /* Adjust as needed */
  left: 100px !important;
  /* Adjust as needed */
  z-index: 1003;
  /* Ensure it's on top of other content */
}


.EngineText {
  position: absolute;
  top: 5px;
  left: 130px;
  font-size: 10px;
}

.FabSettings {
  opacity: 0.9;
  position: fixed !important;
  top: 15px !important;
  /* Adjust as needed */
  left: 20px !important;
  /* Adjust as needed */
  z-index: 1000;
  /* Ensure it's on top of other content */
}

.FabDelete {
  z-index: 1000;
  /* Ensure it's on top of other content */
}

.FabTocOCR {
  position: fixed !important;
  top: 15px !important;
  /* Adjust as needed */
  right: 20px !important;
  /* Adjust as needed */
  z-index: 1000;
  /* Ensure it's on top of other content */
}

.box {
  display: none;
  position: absolute;
}

.box {
  display: none;
  position: absolute;
}

.box::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 5px;
  background: #18c89b;
  box-shadow: 0 0 50px 20px #18c89b;
  clip-path: inset(0);
  animation:
    x 2s ease-in-out infinite alternate,
    y 4s ease-in-out infinite;
}

@keyframes x {
  to {
    transform: translateX(-100%);
    left: 100%;
  }
}

@keyframes y {
  33% {
    clip-path: inset(0 0 0 -100px);
  }

  50% {
    clip-path: inset(0 0 0 0);
  }

  83% {
    clip-path: inset(0 -100px 0 0);
  }
}

.tts-box {
  position: absolute;
  width: 70px;
  /* Width of the container */
  height: 70px;
  /* Height of the container */
  overflow: hidden;
}

.record-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70px;
  /* Size of the container for the record */
  height: 70px;
  /* Size of the container for the record */
  transform: translate(-50%, -50%);
  animation: spin 3s linear infinite;
}

.record {
  width: 70px;
  /* Size of the record */
  height: 70px;
  /* Size of the record */
  background: radial-gradient(circle, #333, #000);
  /* Dark color for the record */
  border-radius: 50%;
  /* Makes the record circular */
  position: relative;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.8);
  /* Inner shadow to give a realistic look */
  z-index: 0;
  /* Ensure it's below the grooves and holes */
}

.groove {
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.203);
  /* White color for grooves */
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
  z-index: 1;
  /* Ensure grooves are above the record */
}

.groove:nth-child(1) {
  width: 60px;
  /* Size of the groove */
  height: 60px;
  /* Size of the groove */
  border-width: 0.5px;
  /* Narrow border */
}

.groove:nth-child(2) {
  width: 50px;
  /* Size of the groove */
  height: 50px;
  /* Size of the groove */
  border-width: 0.5px;
  /* Narrow border */
}

.groove:nth-child(3) {
  width: 40px;
  /* Size of the groove */
  height: 40px;
  /* Size of the groove */
  border-width: 0.5px;
  /* Narrow border */
}

.center-hole {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  /* Size of the center hole */
  height: 14px;
  /* Size of the center hole */
  background: #595656;
  /* Color of the center hole */
  border-radius: 50%;
  /* Makes the hole circular */
  transform: translate(-50%, -50%);
  z-index: 3;
  /* Ensure it's above the record and grooves */
}

.inner-hole {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6px;
  /* Size of the small inner hole */
  height: 6px;
  /* Size of the small inner hole */
  background: #D3D3D3;
  /* Light gray color for the inner hole */
  border-radius: 50%;
  /* Makes the hole circular */
  transform: translate(-50%, -50%);
  z-index: 3;
  /* Ensure it's above the center-hole */
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.tts-logo {
  position: absolute;
  top: 50%;
  /* Center the logo vertically */
  left: 50%;
  width: 40px;
  /* Smaller image size */
  height: auto;
  /* Maintain aspect ratio */
  transform: translate(-50%, -50%);
  z-index: 1;
  /* Ensure logo is below the holes */
  animation: spin 5s linear infinite;
  /* Apply the same spin animation */
}

.settings-header {
  font-size: 20px;
}

.settings-subheader {
  font-size: 17px;
  font-weight: 500;
}

.settings-caption {
  padding: 0px;
  font-size: 14px;
}

.tts-mode {
  text-align: left;
  font-size: 1.40rem;
  /* Base font size for readability */
  font-weight: 400;
  /* Normal weight for body text */
  line-height: 1.6;
  /* Comfortable line spacing */
  padding: 2rem;
  /* Adds padding for better readability */
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

  /* A serif font for a book-like feel */
  /* Dark gray text for high contrast but not pure black */
}

.tts-mode-default {
  text-align: left;
  font-size: 1.30rem;
  margin-bottom: 30px;
  /* A serif font for a book-like feel */
  /* Dark gray text for high contrast but not pure black */
}

/* Headings */
.tts-mode h1,
.tts-mode h2,
.tts-mode h3 {
  font-family: 'Merriweather', serif;
  /* Optional different serif for headings */
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-weight: 700;
  /* Bold for emphasis */
}

/* Paragraphs */
.tts-mode p {
  margin-bottom: 1.5rem;
  /* Adds space between paragraphs */
}

/* Blockquotes */
.tts-mode blockquote {
  font-style: italic;
  color: #666;
  border-left: 4px solid #ddd;
  padding-left: 1rem;
  margin-left: 0;
}

/* Images */
.tts-mode img {
  max-width: 100%;
  height: auto;
  margin: 1rem 0;
}

/* Links */
.tts-mode a {
  color: #0066cc;
  /* A pleasant blue for links */
  text-decoration: none;
  border-bottom: 2px solid #0066cc;
  /* Underline to distinguish links */
}

.tts-mode a:hover {
  color: #004999;
  border-bottom: 2px solid #004999;
}

@media (max-width: 800px) {
  .pageTitle {
    display: none;
  }

  .FabScan {
    display: none
  }

  .FabScanSmall {
    display: block
  }

  .FabComplete {
    display: none
  }

  .FabCompleteSmall {
    display: block
  }
}

.fadein {
  animation: fade-in 3s forwards;
  /* Ensure animation ends with full opacity */
}

.fadeout {
  animation: fade-out 3s forwards;
  /* Ensure animation ends with zero opacity */
}

.hidden {
  display: none;
  /* Hide element completely */
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.TTSLoader {
  align-items: center;
  padding: 100px;
  width: 150px;
  margin: auto;
}

.highlight-box {
  background-color: rgba(255, 235, 59, 0.3);
  /* Yellow background */
  border-radius: 4px;
  padding: 2px;
  transition: background-color 0.2s ease;
}


#tts-mode {
  text-align: left;
  padding: 0px 150px 150px 20px;
  word-break: normal;
  /* normal size - 1.30; mobile friendly -1.85
  font-size: 1.30rem;
  */
  font-size: 1.85rem;
  /* Default color for all words */

  /* Preserves line breaks and spaces */
  /* Ensure words wrap properly */
}


/* Main highlight animation  */
.highlight {
  animation: fade-in-color 3s ease-in forwards;
  color: #ff0000;
  opacity: 1;
}

@keyframes fade-in-color {
  0% {
    color: #ff0000;
    opacity: 1;
  }

  100% {
    color: #d43737;
    opacity: 1;
  }
}



.highlight-line {
  background-color: #444444;
  /* Background color to highlight the line */
  color: #ffffff;
  /* Text color for highlighted lines */
  padding: 5px;
  border-radius: 4px;
}

.bright-highlight {
  background-color: yellow;
  /* Bright yellow for words within ±2 range */
  transition: background-color 0.3s ease;
  /* Smooth transition */
}

.shimmer {
  color: grey;
  display: inline-block;
  mask: linear-gradient(-120deg, #000 30%, rgba(0, 0, 0, 0.572), #000 70%) right/350% 100%;
  animation: shimmer 2.5s infinite;
  font-size: 50px;
}


@keyframes shimmer {
  100% {
    mask-position: left
  }
}