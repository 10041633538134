.LearnMore {
    margin: auto;
    width: 300px;
    padding-bottom: 30px;
}



.AuthButtons {
    margin-bottom: 20px;
}

.GoogleButton {
    margin: auto;
}

.Diff {
    width: 900px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 20px;
}

.Stepper {
    width: 1000px;
    margin: auto;
    padding: 25px;
    background-color: rgba(255, 255, 255, 0.266);
    border: solid black 0px;
    border-radius: 5px;
}

.Section {
    margin-top: 40px;
    height: 100vh;
    width: 100vw;
}

.Login {
    padding-top: 30px;
    background-color: var(--background-color);
    height: 100%;
    width: 100vw;
}

.SectionWhite {
    height: 100%;
    width: 100vw;
    padding: 0px 0px 40px 0px;
    background-color: var(--section-white-background-color);
}

.SectionExport {
    height: auto;
    width: 100vw;
    padding: 40px 0px;
    background-color: var(--background-color);
}

.LearnMore {
    margin-top: 100px;
    margin-bottom: 10px;
}

.MuiStepLabel-labelContainer span {
    font-size: large;
}

.Copy {
    margin: auto;
    width: 600px;
    text-align: left;
    font-size: 20px
}

.OCRStep:hover {
    cursor: pointer;
    color: #8f9797
}

.AssistStep:hover {
    cursor: pointer;
    color: rgba(135, 117, 195, 0.855);
}

.TTSStep:hover {
    cursor: pointer;
    color: rgba(218, 165, 32, 0.855);
}

.StepText {
    color: var(--text-color)
}

.ProofStep:hover {
    cursor: pointer;
    color: rgba(15, 169, 66, 0.855);
}

.ExportStep:hover {
    cursor: pointer;
    color: rgba(85, 126, 207, 0.855);
}

.SectionGray {
    padding: 20px;
    background-color: var(--background-color);
    height: auto;
    width: 100%;
}

.FlowChart {
    border-radius: 10px;
    display: 'inline-block';
    width: '400px';
    margin: 'auto';
    background-color: rgb(245, 245, 245)
}

.FlowChartImg {
    width: 80%;
    height: auto;
}

.HighlightCopy {
    font-weight: lighter;
    margin: auto;
    width: 50%;
    font-size: 32px;
    border-top: 5px solid tan;
    border-bottom: 5px solid tan;
}

@media (max-width: 768px) {

    html,
    body {
        overflow-x: hidden;
    }

    .Stepper {
        display: none;
    }

    .Copy {
        margin: auto;
        width: 90vw;
        text-align: left;
        font-size: 20px;
    }

    .SectionWhite {
        word-break: keep-all;
    }

    .Wrapper {
        word-break: keep-all;
        background-color: var(--background-color);
    }


    .Diff {
        width: 100%;
        margin: auto;
        margin-top: 40px;
        margin-bottom: 20px;
    }
}