.AceEditor {
    margin: auto;
    width: 1000px;
}

#line-menu {
    display: none;
    /* Initially hidden */
    position: absolute;
    z-index: 1000;
    /* Ensure it appears above other content */
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 8px;
    /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
}

/* Add to your CSS file */
.ace_gutter-cell .gutter-menu-icon {
    display: none;
    /* Hide the icon by default */
    position: absolute;
    right: 5px;
    /* Adjust based on your layout */
    cursor: pointer;
}

.ace_gutter-cell:hover .gutter-menu-icon {
    display: inline;
    /* Show the icon on hover */
}

.MuiDialog-root .MuiGrid-root .custom-ace-editor .ace_gutter,
.MuiDialog-root .MuiGrid-root .custom-ace-editor .ace_scroller,
.MuiDialog-root .MuiGrid-root .custom-ace-editor .ace_content,
.MuiPaper-root-MuiDialog-paper {
  box-shadow: none !important;
}

