.Book {
  background-color: forestgreen;
  cursor: pointer;
}

.Cover {
  cursor: pointer;
  height: 300px !important;
  width: auto !important;
  border-radius: 5px;
}

.BooksDiv {
  margin-top: 0px;
  ;
}

.ShelfImage {
  margin: auto;
  display: block;
  width: 301px;
}

.Bookshelf {
  margin: auto;
  width: auto;
}

.NavLinkBooks {
  margin: 10px;
  font-size: 22px;
}

.NoBook {
  width: 200px;
  margin: auto;
}

.BookTitle {
  text-decoration: none;
  font-size: 22px;
}

.BookAuthor {
  text-decoration: none;
  font-size: 14px;
}

.Bookshelf {
  display: flex;
  margin: auto;
}

.Loader {
  margin: auto;
  width: 200px;
}

.Nav {
  width: 100%
}

a {
  text-decoration: none;
}

:root {
  --background-color: #E4E7DC;
  --section-white-background-color: whitesmoke;
  --close-icon-color: transparent;
  --white-background-color: #fff;
  --text-color: #000;
  --tokens-text-color: #313132;
  --input-background-color: #fff;
  --textfield-background-color: #E4E7DC;
  --input-text-color: #000;
  --input-border-color: #ccc;
  --textfield-border-color: #000;
  --label-color: #000;
  --accordion-background-color: #f5f5f5;
  --accordion-text-color: #000;
  --link-color: #313132;
  /* Default link color */
  --link-hover-color: #6e7073;
  /* Link hover color */
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #2C2C2C;
    --white-background-color: #2C2C2C;
    --close-icon-color: white;
    --section-white-background-color: #414141;
    --text-color: #FFF;
    --tokens-text-color: #FFF;
    --input-background-color: #333;
    --textfield-background-color: #ffffff;
    --input-text-color: #FFF;
    --textfield-border-color: #d4d0d0;
    --input-border-color: #555;
    --label-color: #FFF;
    --accordion-background-color: #444;
    --accordion-text-color: #FFF;
    --link-color: #8ab4f8;
    /* Link color for dark mode */
    --link-hover-color: #8ab4f8;
  }
}

body {
  background: var(--background-color);
  color: var(--text-color);
  /* Add more styles as needed */
}

textarea {
  background-color: var(--input-background-color);
  color: var(--input-text-color);
  border: 1px solid var(--input-border-color);
}


.link {
  color: var(--link-color);
  text-decoration: underline;
}

.link:hover {
  color: var(--link-hover-color);
  text-decoration: underline;
}

.BookLink {
  color: var(--link-color);
  color: '#202020'
}

.BookLink:hover {
  color: var(--link-hover-color);
}

.TokensText {
  color: var(--tokens-text-color);
}